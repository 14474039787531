import '../UI/styles/components/Footer.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrivacyPolicyRu } from './PrivacyPolicyRu';
import { TermsAndConditionsRu } from './TermsAndConditionsRu';

export const FooterSignedOut = () => {
    const { t } = useTranslation();

    return (
        <div className="footer">
            <div className="footer__confidential">
                <Link className="footer__privacy" to="/privacy">
                    {t('Privacy Policy')}
                </Link>
                <Link className="footer__terms" to="/terms">
                    {t('Terms and conditions')}
                </Link>
            </div>
        </div>
    );
};

export const TermsAndConditions = () => {
    return <TermsAndConditionsRu />;
};
export const PrivacyPolicy = () => {
    return <PrivacyPolicyRu />;
};
