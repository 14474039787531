import { atom } from 'recoil';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

const defaultState = undefined;

export const SHARED_MASTER_CLASS_LIST_STORE = 'sharedMasterClassListStore';
type ItemsList = undefined | SwMasterClassList;

export const sharedMasterClassListStore = atom<ItemsList>({
    key: SHARED_MASTER_CLASS_LIST_STORE,
    default: defaultState,
});
