import '../UI/styles/components/Footer.scss';

import { Auth } from 'aws-amplify';
import Avatar from 'react-avatar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { isUserPriveleged } from '../helpers/AuthHelpers';

export const Footer = () => {
    const { t } = useTranslation();
    const [priveleged, setPriveleged] = useState(false);

    useEffect(() => {
        async function queryUserData(): Promise<void> {
            setPriveleged(await isUserPriveleged());
        }
        queryUserData();
    }, []);

    return (
        <div className="footer">
            <div className="footer__buttons_admin">
                {priveleged ? (
                    <Link to="/admin">{t('Admin access')}</Link>
                ) : null}
            </div>
            <Avatar
                className="header__profile_icon"
                round={true}
                unstyled={false}
                size={'50'}
                value={t('Profile')}
                color={'#bfbfbf'}
            />
            {/* <AmplifySignOut /> */}
            <a
                href="/"
                onClick={(event) => {
                    console.log('signing out');
                    Auth.signOut();
                }}
            >
                {t('Sign Out')}
            </a>
            {/* <a
                href="/"
                onClick={() => {
                    console.log('help clicked');
                }}
            >
                {t('Help')}
            </a> */}
        </div>
    );
};
