import './UI/styles/layout/_media.scss';
import './UI/styles/layout/index.scss';

import './sentry';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    PrivacyPolicy,
    TermsAndConditions,
} from './components/FooterSignedOut';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <RecoilRoot>
                <Switch>
                    <Route exact path="/privacy">
                        <PrivacyPolicy />
                    </Route>
                    <Route exact path="/terms">
                        <TermsAndConditions />
                    </Route>
                    <Route path="/">
                        <App />
                    </Route>
                </Switch>
            </RecoilRoot>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
