import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SwMasterClass } from 'sw-commons/lib/models/SwMasterClass';

import { SwApi } from '../../../api-client/SwApi';
import { contentEditState, ViewMode } from '../../../store/ContentEditStore';
import { AddNewItemForm } from './AddNewClassForm';

const AdminMasterClassList = () => {
    const [contentState, setContentState] = useRecoilState(contentEditState);
    const { t } = useTranslation();

    useEffect(() => {
        const apiCall = async () => {
            if (!contentState.loaded) {
                const response = await SwApi.adminListMasterClass();
                if (response) {
                    setContentState({
                        loaded: true,
                        items: response,
                        viewMode: ViewMode.List,
                        edited: contentState.edited,
                    });
                } else {
                    console.warn('Not setting mk list');
                }
            }
        };
        apiCall();
    }, [contentState, setContentState]);

    const addNewMk = (name: string): void => {
        if (name.length === 0) {
            console.warn('No MK name provided, not adding');
            return;
        }
        const clone = [...contentState.items];

        console.log('before', contentState);
        clone.push({
            name: name,
            id: Number(clone.length + 1),
        });
        console.log('after', clone);

        setContentState({
            items: clone,
            edited: true,
            loaded: true,
            viewMode: ViewMode.List,
        });
        console.log('final', contentState);
    };

    const itemsView = () => {
        if (!contentState.loaded) {
            return <p>{t('Loading...')}</p>;
        }
        if (contentState.items.length === 0) {
            return <p>{t('No items')}</p>;
        }
        return (
            <div>
                <h3>{t('Master Classes')}</h3>
                <ul className="list">
                    {contentState.items.map((item: SwMasterClass) => {
                        return (
                            <li key={item.id}>
                                <p
                                    onClick={() => {
                                        setContentState({
                                            viewingItem: {
                                                item: item,
                                            },
                                            items: contentState.items,
                                            viewMode: ViewMode.Folders,
                                            edited: contentState.edited,
                                            loaded: contentState.loaded,
                                        });
                                    }}
                                >
                                    {item.name}
                                </p>
                            </li>
                        );
                    })}
                </ul>
                <AddNewItemForm handler={addNewMk} />
            </div>
        );
    };
    return itemsView();
};

export { AdminMasterClassList };
