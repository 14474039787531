import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SwResourceTypes } from 'sw-commons/lib/models/SwMasterClass';

export function AddNewResourceForm(props: {
    handler: (name: string, type: SwResourceTypes) => void;
}) {
    const [resourceName, setResourceName] = useState<string>('');
    const [resourceType, setResourceType] = useState<SwResourceTypes>(
        SwResourceTypes.TEXT
    );
    const { t } = useTranslation();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (!resourceName || !resourceType) {
            throw Error('Cant submit new resource, missing name or type');
        }
        event.preventDefault();
        console.debug('Submitting', resourceType, resourceName);
        props.handler(resourceName, resourceType);
        setResourceName('');
        setResourceType(resourceType);
    };

    const submitValueText = t('Add new');

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input
                    onChange={(e) => setResourceName(e.target.value)}
                    type="text"
                    name=""
                    value={resourceName}
                />
                <label>
                    <select
                        value={resourceType}
                        onChange={(e) =>
                            setResourceType(e.target.value as SwResourceTypes)
                        }
                    >
                        <option value={SwResourceTypes.TEXT}>
                            {t('Text')}
                        </option>
                        <option value={SwResourceTypes.VIDEO}>
                            {t('Video')}
                        </option>
                    </select>
                </label>
                <input
                    type="submit"
                    value={submitValueText}
                    disabled={!resourceName}
                />
            </form>
        </div>
    );
}
