import { API, Auth } from 'aws-amplify';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

export type SignedUrlResponse = {
    result: {
        signedUrl: SignedUrl;
    };
};
export type SignedUrl = string;

export async function getObject(objectKey: string): Promise<SignedUrl> {
    console.log('Asking for', objectKey);

    const apiName = 'object';
    const path = '/object';
    const myInit = {
        headers: {
            Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
        },
        queryStringParameters: {
            objectKey: objectKey,
        },
    };

    const response = (await API.get(
        apiName,
        path,
        myInit
    )) as SignedUrlResponse;
    if (!response) {
        throw new Error('Failed to get resource');
    }
    const url = response.result?.signedUrl;
    if (!url || url.length === 0) {
        throw new Error('Failed to get the url for the object' + objectKey);
    }
    console.log('Got url: ', url);
    return url;
}

export type ListMasterClassResponse = SwMasterClassList;
