export const TermsAndConditionsRu = () => {
    return (
        <div>
            <div className="panel-body" id="document-content">
                <h3>Пользовательское Соглашение</h3>
                Настоящее Пользовательское Соглашение (Далее Соглашение)
                регулирует отношения между владельцем sweet-weekend.ru (далее
                SweetWeekend или Администрация) с одной стороны и пользователем
                сайта с другой.
                <br />
                Сайт SweetWeekend не является средством массовой информации.
                <br />
                <br />
                Используя сайт, Вы соглашаетесь с условиями данного соглашения.
                <br />
                <b>
                    Если Вы не согласны с условиями данного соглашения, не
                    используйте сайт SweetWeekend!
                </b>
                <br />
                <br />
                <h3>Права и обязанности сторон</h3>
                <b>Пользователь имеет право:</b>
                <br />- осуществлять поиск информации на сайте
                <br />- получать информацию на сайте
                <br />- требовать от администрации скрытия любой информации о
                пользователе
                <br />- использовать информацию сайта в личных некоммерческих
                целях
                <br />
                <br />
                <b>Администрация имеет право:</b>
                <br />- по своему усмотрению и необходимости создавать,
                изменять, отменять правила
                <br />- ограничивать доступ к любой информации на сайте
                <br />- создавать, изменять, удалять информацию
                <br />- удалять учетные записи
                <br />- отказывать в регистрации без объяснения причин
                <br />
                <br />
                <b>Пользователь обязуется:</b>
                <br />- обновлять Персональные данные, предоставленные при
                регистрации, в случае их изменения
                <br />- не копировать информацию с других источников
                <br />- не нарушать работоспособность сайта
                <br />- не создавать несколько учётных записей на Сайте, если
                фактически они принадлежат одному и тому же лицу
                <br />- не совершать действия, направленные на введение других
                Пользователей в заблуждение
                <br />- не передавать в пользование свою учетную запись и/или
                логин и пароль своей учетной записи третьим лицам
                <br />- не регистрировать учетную запись от имени или вместо
                другого лица за исключением случаев, предусмотренных
                законодательством РФ
                <br />- не использовать скрипты (программы) для
                автоматизированного сбора информации и/или взаимодействия с
                Сайтом и его Сервисами
                <br />
                <br />
                <b>Администрация обязуется:</b>
                <br />- поддерживать работоспособность сайта за исключением
                случаев, когда это невозможно по независящим от Администрации
                причинам.
                <br />- осуществлять разностороннюю защиту учетной записи
                Пользователя
                <br />
                <br />
                <h3>Ответственность сторон</h3>
                - администрация не несет никакой ответственности за услуги,
                предоставляемые третьими лицами
                <br />- в случае возникновения форс-мажорной ситуации (боевые
                действия, чрезвычайное положение, стихийное бедствие и т. д.)
                Администрация не гарантирует сохранность информации, размещённой
                Пользователем, а также бесперебойную работу информационного
                ресурса
                <br />
                <br />
                <h3>Условия действия Соглашения</h3>
                Данное Соглашение вступает в силу при любом использовании
                данного сайта.
                <br />
                Соглашение перестает действовать при появлении его новой версии.
                <br />
                Администрация оставляет за собой право в одностороннем порядке
                изменять данное соглашение по своему усмотрению.
                <br />
                При изменении соглашения, в некоторых случаях, администрация
                может оповестить пользователей удобным для нее способом.
                <br />
                <br />
                <br />
            </div>
        </div>
    );
};
