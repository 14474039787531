import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SwMasterClass } from 'sw-commons/lib/models/SwMasterClass';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

import { SwApi } from '../../../api-client/SwApi';
import { sharedAdminMasterClassListStore } from '../../../store/SharedAdminMasterClassListStore';

// todo consume from commons
export type SwPermission = number;
export type SwPermissions = SwPermission[];

// todo replace with actual network call
const getUserPermissions = async (email: string): Promise<SwPermissions> => {
    console.info(`Fetching ${email} permissions`);
    return await SwApi.getPermissions(email);
};

const checkboxesWithNames = (
    allItems: SwMasterClassList,
    userPermissions: SwPermissions,
    setLocalState: (permissions: SwPermissions) => void
): JSX.Element => {
    const out: [SwMasterClass, boolean][] = allItems.map((availableItem) => {
        return [availableItem, userPermissions.includes(availableItem.id)];
    });
    return (
        <div>
            {out.map((item, i) => {
                const mk = item[0];
                const allowed = item[1];
                return (
                    <div key={i.toString()}>
                        <label htmlFor={mk.id.toString()}>{mk.name}</label>
                        <input
                            id={i.toString()}
                            name={mk.name}
                            type="checkbox"
                            checked={allowed}
                            onChange={(e) => {
                                console.debug(
                                    'checkbox clicked, updating state'
                                );
                                const clone = [...userPermissions];
                                if (allowed) {
                                    clone.splice(clone.indexOf(mk.id), 1);
                                } else {
                                    clone.push(mk.id);
                                }

                                setLocalState(clone);
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export const AdminAccessManager = () => {
    const [items, setItems] = useRecoilState(sharedAdminMasterClassListStore);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<undefined | Error>();
    const [remotePermissions, setRemotePermissions] = useState<SwPermissions>();
    const [localPermissions, setLocalPermisions] = useState<SwPermissions>();
    const [inputValue, setInputValue] = useState('imnot.xen@gmail.com');
    const { t } = useTranslation();

    useEffect(() => {
        // todo refactor to share the same state
        // with content manager to avoid extra requests
        const apiCall = async () => {
            if (!loaded) {
                setLoading(true);
                const response = await SwApi.adminListMasterClass();
                if (response) {
                    setItems(response);
                    setLoaded(true);
                    setLoading(false);
                } else {
                    console.error('No response from API');
                    setError(t('No response from API'));
                }
            }
        };
        apiCall();
    }, [loaded, setLoaded, setItems, t]);

    const handleSubmitPermissionsSearch = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        setLocalPermisions(undefined);
        if (loaded && (!items || items.length === 0)) {
            setError(new Error('No data to match permissions against'));
        }
        console.debug('Fetching permissions');
        setLoading(true);
        try {
            const remote = await getUserPermissions(inputValue);
            setRemotePermissions(remote);
            setLocalPermisions(remote);
            setLoading(false);
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    };

    const findUserSubmitInputText = t('Find user');

    return (
        <div>
            <h2>{t('Access Manager')}</h2>
            <div>
                <form onSubmit={handleSubmitPermissionsSearch}>
                    <input
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            setLocalPermisions(undefined);
                            setRemotePermissions(undefined);
                        }}
                        type="text"
                        name="name"
                        value={inputValue} // read value
                        disabled={loading}
                    />
                    <input type="submit" value={findUserSubmitInputText} />
                    {error ? <p>{error.message}</p> : null}
                </form>
            </div>
            <div>
                {(function () {
                    if (!localPermissions) {
                        return null;
                    }
                    console.log('local:', localPermissions);
                    console.log('remote:', remotePermissions);
                    return (
                        <Results
                            email={inputValue}
                            permissions={localPermissions}
                            setLocalState={setLocalPermisions}
                            items={items}
                        />
                    );
                })()}
                {localPermissions?.join() !== remotePermissions?.join() ? (
                    <button
                        onClick={async (e) => {
                            console.debug('saving local state to remote');
                            setLoading(true);

                            try {
                                await SwApi.setPermissions(
                                    inputValue,
                                    localPermissions || []
                                );
                            } catch (e) {
                                setError(e);
                                setLoading(false);
                            }
                            setRemotePermissions(localPermissions);
                            setLoading(false);
                        }}
                        disabled={loading}
                    >
                        {t('Save')}
                    </button>
                ) : null}
            </div>
        </div>
    );
};

const Results = (props: {
    email: string;
    permissions: SwPermissions;
    items: SwMasterClassList;
    setLocalState: (permissions: SwPermissions) => void;
}) => {
    const { t } = useTranslation();

    console.log('results rendering');
    return (
        <div>
            <h3>
                {t('Search results:')} {props.email}
            </h3>
            {checkboxesWithNames(
                props.items,
                props.permissions,
                props.setLocalState
            )}
        </div>
    );
};
