import './UI/styles/components/App.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import Amplify, { Auth, Hub } from 'aws-amplify';
import { Location } from 'history';
import { get as lsGet } from 'local-storage';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import GoogleButton from 'react-google-button';

import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
    AmplifyAuthenticator,
    AmplifySignIn,
    AmplifySignUp,
} from '@aws-amplify/ui-react';

import { SwApi } from './api-client/SwApi';
import { configClient } from './client/aws-exports';
import AdminApp from './components/AdminApp';
import ClientApp from './components/ClientApp';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import i18n from './i18nn';
import { mockedApiState } from './store/MockedApiStore';
import { useTranslation } from 'react-i18next';
import { Logo } from './components/Logo';
import { FooterSignedOut } from './components/FooterSignedOut';
import { userState } from './store/UserState';
import { getUserState } from './helpers/AuthHelpers';

Amplify.configure(configClient);

// outside of components to make sure all first calls will respect the saved state
// the state will then be synchronised in bootstrap
const persistentApiMockState: boolean | undefined = lsGet('isApiMocked');
SwApi.setUseMocks(persistentApiMockState ?? false);

const App = () => {
    const location = useLocation<Location>();
    const [bootstrapped, setBootstrapped] = useState<boolean>(false);
    const setMockedApi = useSetRecoilState(mockedApiState);
    const [state, setState] = useRecoilState(userState);
    const [authState, setAuthState] = React.useState<AuthState>();

    const { t } = useTranslation();

    const getUser = useCallback(async () => {
        try {
            setState(await getUserState());
        } catch (e) {
            console.warn('No user session:', e);
            setState(undefined);
        }
    }, [setState]);

    useEffect(() => {
        const bootstrap = (): void => {
            if (bootstrapped) {
                return;
            } else {
                setMockedApi(persistentApiMockState ?? false);
                i18n.changeLanguage('ru');
                setBootstrapped(true);
            }
        };

        if (!state) {
            getUser();
        }
        bootstrap();

        return onAuthUIStateChange((nextAuthState, authData) => {
            console.debug('oauthNextState', nextAuthState);
            console.debug('authData', authData);
            setAuthState(nextAuthState);
        });
    }, [location, bootstrapped, setMockedApi, state, getUser]);

    Hub.listen('auth', async ({ payload: { event, data } }) => {
        switch (event) {
            case 'signIn':
                console.log('Sign in', event, data);
                getUser();
                break;
            case 'signOut':
                console.log('Sign out', event, data);
                break;
            default:
                break;
        }
    });

    return authState === AuthState.SignedIn && state?.user ? (
        <div className="App">
            <div className="app">
                <div className="container">
                    <Header />
                    <Route path="/" component={ClientApp} />
                    <Route path="/admin" component={AdminApp} />
                    <Footer />
                </div>
            </div>
        </div>
    ) : (
        <div className="mainpage">
            <AmplifyAuthenticator usernameAlias="email">
                <AmplifySignIn
                    headerText="Школа Sweet Weekend"
                    usernameAlias="email"
                    formFields={[{ type: 'email' }, { type: 'password' }]}
                    slot="sign-in"
                >
                    <div
                        slot="header-subtitle"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Logo />
                    </div>
                    {/* {remove "Sign in with AWS"} */}
                    <div slot="federated-buttons">
                        <GoogleButton
                            type="light"
                            onClick={() => {
                                Auth.federatedSignIn({
                                    provider: 'Google' as any,
                                });
                            }}
                            className="google_oauth_button"
                            label={t('Login with Google')}
                        />
                    </div>
                </AmplifySignIn>

                <AmplifySignUp
                    headerText="Школа Sweet Weekend"
                    usernameAlias="email"
                    slot="sign-up"
                    formFields={[
                        { type: 'email' },
                        // {
                        //     type: 'phone_number',
                        //     dialCode: 7,
                        //     placeholder: '999 99 99',
                        //     required: false,
                        // } as any, // hack around formFields dialCode is not exposed in types
                        { type: 'password', label: t('Create password *') },
                    ]}
                />
            </AmplifyAuthenticator>
            <FooterSignedOut />
        </div>
    );
};

export default App;
