import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';

export async function getUserEmail({
    userInfo,
    session,
}: {
    userInfo?: any;
    session?: any;
}): Promise<string> {
    const _userInfo = userInfo || (await Auth.currentUserInfo());
    const _session = session || (await Auth.currentSession());
    return _userInfo?.attributes?.email || _session.getIdToken().payload?.email;
}

export async function isUserPriveleged(): Promise<boolean> {
    try {
        const session = await Auth.currentSession();
        const sessionIdInfo = jwt_decode<Record<string, unknown>>(
            session.getIdToken().getJwtToken()
        );
        const groups = sessionIdInfo['cognito:groups'];

        if (groups && (groups as string[]).includes('admin')) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.warn(e);
        return false;
    }
}

export async function getUserState() {
    const user = await Auth.currentAuthenticatedUser();
    const userInfo = await Auth.currentUserInfo();
    const session = await Auth.currentSession();
    console.log({ session, user, userInfo });
    return { session, user, userInfo };
}
