import '../UI/styles/components/HomePage.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MasterClassList } from './MasterClassListPage/MasterClassList';

export function Homepage() {
    const [isLoaded, setIsLoaded] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    if (!isLoaded) {
        return null;
    } else {
        return (
            <div className="home">
                <h3 className="home__title">{t('Your library')}</h3>
                <MasterClassList />
            </div>
        );
    }
}
