import '../../UI/styles/components/TextResource.scss';

import {
    convertFromRaw,
    Editor,
    EditorState,
    RawDraftContentState,
} from 'draft-js';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { SwTextResource } from 'sw-commons/lib/models/SwMasterClass';

import { SwApi } from '../../api-client/SwApi';
import { LoadingSpinerComponent } from '../LoadingSpinerComponent';

export const TextResource = ({
    data,
}: {
    data: {
        resource: SwTextResource;
        closeCallback: Function;
    };
}) => {
    const [error, setError] = useState<undefined | Error>();
    const [rawData, setRawData] = useState<undefined | RawDraftContentState>();

    useEffect(() => {
        const apiCall = async () => {
            if (!rawData) {
                console.debug(
                    'No raw data saved, fetching',
                    data.resource.objectKey
                );
                try {
                    const response = await trackPromise(
                        SwApi.fetchSigned(data.resource.objectKey)
                    );
                    console.debug('Fetch returned', response);
                    if (response.blocks) {
                        setRawData(
                            (response as unknown) as RawDraftContentState
                        );
                    } else {
                        console.error(response);
                        throw new Error(
                            'Returned object is not a RawDraftState'
                        );
                    }
                } catch (e) {
                    console.error(e);
                    setError(e);
                }
            }
        };
        apiCall();
    }, [rawData, data.resource.objectKey]);
    return (
        <div className="text-resource">
            {error ? <p>{error.message}</p> : null}
            {rawData ? (
                renderSavedRawContent(rawData)
            ) : (
                <LoadingSpinerComponent />
            )}
        </div>
    );
};

function renderSavedRawContent(rawContent: RawDraftContentState) {
    const content = EditorState.createWithContent(convertFromRaw(rawContent));
    return (
        <Editor
            readOnly={true}
            editorState={content}
            onChange={() => {}}
            editorKey="wtfiszis"
        />
    );
}
