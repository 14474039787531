import { set as lsSet } from 'local-storage';
import { atom, DefaultValue, selector } from 'recoil';

import { SwApi } from '../api-client/SwApi';

export const MOCKED_API_STATE = 'mockedApiState';
const DEFAULT_MOCK_MODE = false;

export const mockedApiState = atom({
    key: MOCKED_API_STATE,
    default: DEFAULT_MOCK_MODE,
});

export const mockedApiSelector = selector<boolean>({
    key: 'TransformSelector',
    get: ({ get }) => {
        const state = get(mockedApiState);
        console.debug('returning atom state:', state);
        return state;
    },
    set: ({ set }, newValue) => {
        console.debug('storing', newValue);
        const newVal =
            newValue instanceof DefaultValue ? DEFAULT_MOCK_MODE : newValue;
        SwApi.setUseMocks(newVal);
        lsSet('isApiMocked', newVal);
        set<boolean>(mockedApiState, newVal);
    },
});
