import { atom } from 'recoil';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

const defaultState = [] as SwMasterClassList;

export const SHARED_ADMIN_MASTER_CLASS_LIST_STORE =
    'sharedAdminMasterClassListStore';
export const sharedAdminMasterClassListStore = atom({
    key: SHARED_ADMIN_MASTER_CLASS_LIST_STORE,
    default: defaultState,
});
