import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { contentEditState, ViewMode } from '../../../store/ContentEditStore';
import { AdminFoldersView } from './AdminFoldersView';
import { AdminFolderView } from './AdminFolderView';
import { AdminMasterClassList } from './AdminMasterClassList';
import { AdminResourceView } from './AdminResourceView';
import { SubmitItemsButton } from './SaveButton';

export const AdminContentManager = () => {
    const [contentState, setContentState] = useRecoilState(contentEditState);
    const { t } = useTranslation();

    const view = () => {
        const masterClass = contentState.viewingItem?.item;
        const folder = contentState.viewingItem?.itemFolder;
        const resource = contentState.viewingItem?.resource;

        switch (contentState.viewMode) {
            case ViewMode.Folders:
                return (
                    <div>
                        <button
                            onClick={() => {
                                setContentState({
                                    items: contentState.items,
                                    viewMode: ViewMode.List,
                                    edited: contentState.edited,
                                    loaded: contentState.loaded,
                                });
                            }}
                        >
                            {t('Back to')} {masterClass?.name}
                        </button>
                        <h3>
                            {masterClass?.name} - {t('Folders')}
                        </h3>
                        <AdminFoldersView />
                    </div>
                );

            case ViewMode.Folder:
                return (
                    <div>
                        <button
                            onClick={() => {
                                setContentState({
                                    viewingItem: {
                                        item: masterClass!,
                                    },
                                    items: contentState.items,
                                    viewMode: ViewMode.Folders,
                                    edited: contentState.edited,
                                    loaded: contentState.loaded,
                                });
                            }}
                        >
                            {t('Back to')} {masterClass?.name} {t('folders')}
                        </button>
                        <h3>
                            {masterClass?.name}: {folder?.folderName}
                        </h3>
                        <AdminFolderView />
                    </div>
                );

            case ViewMode.Resource:
                return (
                    <div>
                        <button
                            onClick={() => {
                                setContentState({
                                    viewingItem: {
                                        item: masterClass!,
                                        itemFolder: folder,
                                        resource: resource,
                                    },
                                    items: contentState.items,
                                    viewMode: ViewMode.Folder,
                                    edited: contentState.edited,
                                    loaded: contentState.loaded,
                                });
                            }}
                        >
                            {t('Back to')} {folder?.folderName}
                        </button>
                        <p>
                            {' '}
                            {masterClass?.name}: {folder?.folderName}
                        </p>
                        <h3>{resource?.name}</h3>
                        <AdminResourceView resource={resource!} />
                    </div>
                );

            default:
                return <AdminMasterClassList />;
        }
    };

    return (
        <div>
            <p>{t('Content manager')}</p>
            {view()}
            {contentState.edited ? <SubmitItemsButton /> : null}
        </div>
    );
};
