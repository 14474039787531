import { atom } from 'recoil';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const userState = atom<
    { session: CognitoUserSession; user: any; userInfo: any } | undefined
>({
    key: 'userState',
    default: undefined,
    dangerouslyAllowMutability: true,
});
