import '../../UI/styles/components/MasterClassFoldersList.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { SwMasterClass, SwResource } from 'sw-commons/lib/models/SwMasterClass';

import { AiOutlineRight } from 'react-icons/ai';
import { GrDocumentVideo, GrDocumentText } from 'react-icons/gr';

import { useHistory } from 'react-router-dom';

export function MasterClassFoldersList({
    mk,
    setShowingResource,
}: {
    mk: SwMasterClass;
    setShowingResource: React.Dispatch<
        React.SetStateAction<false | SwResource>
    >;
}) {
    const { t } = useTranslation();
    let history = useHistory();

    if (!mk.folders || mk.folders.length === 0) {
        return <p>{t('No folders')}</p>;
    }
    return (
        <ul className="masterclass__list">
            {mk.folders.map((folder) => {
                return (
                    <li
                        className="masterclass__item chapter"
                        key={folder.folderName}
                    >
                        <div className="chapter__wrapper">
                            <h3 className="chapter__subtitle subtitle">
                                {folder.folderName}
                            </h3>
                            <ul className="chapter__list">
                                {folder.resources ? (
                                    folder.resources.map((resource) => {
                                        return (
                                            <li
                                                className="chapter__item about"
                                                key={resource.name}
                                            >
                                                <div
                                                    className="about__wrapper"
                                                    onClick={() => {
                                                        console.log(
                                                            'Opening:',
                                                            resource.name,
                                                            resource.type
                                                        );
                                                        setShowingResource(
                                                            resource
                                                        );
                                                        history.push(
                                                            `#${resource.type}`
                                                        );
                                                    }}
                                                >
                                                    <div className="about__icon">
                                                        {resource.type ===
                                                        'video' ? (
                                                            <GrDocumentVideo
                                                                size={20}
                                                            />
                                                        ) : (
                                                            <GrDocumentText
                                                                size={20}
                                                            />
                                                        )}
                                                    </div>
                                                    <p className="about__name">
                                                        {resource.name}
                                                    </p>
                                                    <div className="about__icon">
                                                        <AiOutlineRight
                                                            size={20}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <p>{t('No resources')}</p>
                                )}
                            </ul>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}
