import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { isUserPriveleged } from '../helpers/AuthHelpers';
import { AdminAccessManager } from './Admin/AccessManager/AdminAccessManager';
import { AdminPanel } from './Admin/AdminPanel';
import { AdminContentManager } from './Admin/ContentManager/AdminContentManager';
import { RawEditView } from './Admin/ContentManager/RawEditView';

const AdminApp = () => {
    const [shouldRender, setShouldRender] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);

    useEffect(() => {
        async function queryUserData(): Promise<void> {
            const isAdmin = await isUserPriveleged();
            setHasAccess(isAdmin);
            setShouldRender(true);
        }
        queryUserData();
    }, []);
    if (!shouldRender) {
        return null;
    }
    if (!hasAccess) {
        return <Redirect to="/" />;
    }
    return (
        <div className="AdminApp">
            <div className="Content">
                <Switch>
                    <Route exact path="/admin" children={<AdminPanel />} />
                    <Route
                        exact
                        path="/admin/access"
                        children={<AdminAccessManager />}
                    />
                    <Route
                        exact
                        path="/admin/content"
                        children={<AdminContentManager />}
                    />
                    <Route
                        exact
                        path="/admin/content/raw"
                        children={<RawEditView />}
                    />
                </Switch>
            </div>
        </div>
    );
};

export default AdminApp;
