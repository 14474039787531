import { atom } from 'recoil';
import {
    SwFolder,
    SwMasterClass,
    SwResource,
} from 'sw-commons/lib/models/SwMasterClass';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

export enum ViewMode {
    List = 'list',
    Folders = 'folders',
    Folder = 'folder',
    Resource = 'resource',
}

export interface ViewingItem {
    item: SwMasterClass;
    itemFolder?: SwFolder;
    resource?: SwResource;
}

export interface ContentState {
    loaded: boolean;
    edited: boolean;
    saving?: boolean;
    items: SwMasterClassList;
    viewingItem?: ViewingItem;
    viewMode: ViewMode;
}

const defaultState: ContentState = {
    loaded: false,
    edited: false,
    saving: false,
    items: [] as SwMasterClassList,
    viewMode: ViewMode.List,
};

export const CONTENT_EDIT_STATE = 'contentEditState';
export const contentEditState = atom({
    key: CONTENT_EDIT_STATE,
    default: defaultState,
});
