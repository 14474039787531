import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    SwResource,
    SwResourceTypes,
} from 'sw-commons/lib/models/SwMasterClass';

import { TextResource } from '../ResourceView/TextResource';
import { VideoResource } from '../ResourceView/VideoResource';

export function ResourceView({
    isShowingResource,
    closeCallback,
}: {
    isShowingResource: SwResource;
    closeCallback: () => void;
}) {
    let history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            console.log('location', location);
            console.log('action', history.action);
            if (history.action === 'POP') {
                if (history.location.hash.length === 0) {
                    // close
                    closeCallback();
                } else {
                    // open?
                }
            }
        });
    });

    return (
        <div className="masterClassResource">
            {isShowingResource.type === SwResourceTypes.VIDEO ? (
                <VideoResource
                    data={{
                        resource: isShowingResource,
                        closeCallback,
                    }}
                />
            ) : (
                <TextResource
                    data={{
                        resource: isShowingResource,
                        closeCallback,
                    }}
                />
            )}
        </div>
    );
}
