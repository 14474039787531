/* eslint-disable @typescript-eslint/no-unused-vars */
import '../../UI/styles/components/MasterClassList.scss';

import React, { useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useRecoilState } from 'recoil';

import { SwApi } from '../../api-client/SwApi';
import { sharedMasterClassListStore } from '../../store/SharedMasterClassListStore';
import { LoadingSpinerComponent } from '../LoadingSpinerComponent';
import { MasterClassListItem } from './MasterClassListItem';
import { useTranslation } from 'react-i18next';

const MasterClassList = () => {
    const [items, setItems] = useRecoilState(sharedMasterClassListStore);
    const [error, setError] = useState<Error | undefined>(undefined);
    const { promiseInProgress } = usePromiseTracker();
    const { t } = useTranslation();

    useEffect(() => {
        const apiCall = async () => {
            try {
                const result = await trackPromise(SwApi.listMasterClass());
                setItems(result);
            } catch (e) {
                console.error(e);
                setError(e);
            }
        };

        if (!promiseInProgress && !items && !error) {
            trackPromise(apiCall());
        }
    });

    const view = () => {
        if (error) {
            return <p>{error.message}</p>;
        }
        if (promiseInProgress || !items) {
            return <LoadingSpinerComponent />;
        }
        if (items.length === 0) {
            return <p>{t('No avaialable master classes')}</p>;
        }

        const itemsSorted = [...items].sort((a, b) => a.id - b.id);
        return (
            <ul className="masterclasses">
                {itemsSorted.map((item) => {
                    return (
                        <li key={item.id} className="masterclasses__item strip">
                            <MasterClassListItem data={item} />
                        </li>
                    );
                })}
            </ul>
        );
    };

    return view();
};

export { MasterClassList };
