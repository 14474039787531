import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SwFolder, SwMasterClass } from 'sw-commons/lib/models/SwMasterClass';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

import {
    contentEditState,
    ViewingItem,
    ViewMode,
} from '../../../store/ContentEditStore';
import { AddNewItemForm } from './AddNewClassForm';

export const AdminFoldersView = () => {
    const [contentState, setContentState] = useRecoilState(contentEditState);
    const { t } = useTranslation();

    const foldersList = (items?: SwFolder[]) => {
        if (!items || items.length === 0) {
            return <p>{t('No folders')}</p>;
        }
        return (
            <div>
                {items?.map((folder) => {
                    return (
                        <div className="centered" key={folder.folderName}>
                            <p
                                onClick={() => {
                                    setContentState({
                                        items: contentState.items,
                                        viewMode: ViewMode.Folder,
                                        viewingItem: {
                                            item: contentState.viewingItem!
                                                .item,
                                            itemFolder: folder,
                                        },
                                        edited: contentState.edited,
                                        loaded: contentState.loaded,
                                    });
                                }}
                            >
                                {folder.folderName}
                            </p>
                            <div
                                onClick={() => {
                                    const { items, viewingItem } = deleteFolder(
                                        folder,
                                        contentState.items,
                                        contentState.viewingItem?.item!
                                    );

                                    console.log('Folder deleted, redrawing');

                                    setContentState({
                                        items: items,
                                        viewMode: ViewMode.Folders,
                                        viewingItem: viewingItem,
                                        edited: true,
                                        loaded: contentState.loaded,
                                    });
                                }}
                            >
                                <div className="deleteButton">❌</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const deleteFolder = (
        folderToDelete: SwFolder,
        items: SwMasterClassList,
        item: SwMasterClass
    ): { items: SwMasterClassList; viewingItem: ViewingItem } => {
        console.log('Deleting folder');

        const clone: SwMasterClass[] = [];
        items.forEach((e) => clone.push(JSON.parse(JSON.stringify(e))));

        const itemToEdit = clone.find((i) => i.id === item.id);
        if (!itemToEdit) {
            throw Error('Cant find item to edit');
        }

        console.debug('item to edit:', itemToEdit);
        console.debug('folder to delete:', folderToDelete);

        const indexToDelete = itemToEdit?.folders?.findIndex((f) => {
            console.log('comparing', f.folderName, folderToDelete.folderName);
            return f.folderName === folderToDelete.folderName;
        });

        console.debug('index to delete', indexToDelete);

        if (indexToDelete === undefined || indexToDelete === -1) {
            throw Error('Cant find folder to delete');
        }

        const deleted = itemToEdit?.folders?.splice(indexToDelete, 1);
        if (!deleted) {
            throw Error('Failed to delete anything');
        }

        return {
            items: clone,
            viewingItem: {
                item: itemToEdit,
            },
        };
    };

    const addNewFolder = (name: string): void => {
        if (name.length === 0) {
            console.warn('No folder name provided, not adding');
            return;
        }
        const viewingItemId = contentState.viewingItem?.item.id;
        if (!viewingItemId) {
            throw Error('Expected viewing a MK');
        }

        const clone: SwMasterClass[] = [];
        contentState.items.forEach((e) =>
            clone.push(JSON.parse(JSON.stringify(e)))
        );

        const mkUnderedit = clone.find((mk) => mk.id === viewingItemId);
        if (!mkUnderedit) {
            throw new Error('Not found MK to edit');
        }

        console.debug('Editing folders for', mkUnderedit);

        if (!mkUnderedit.folders) {
            mkUnderedit.folders = [];
        }

        mkUnderedit.folders?.push({
            folderName: name,
        });

        console.debug('Setting items: ', clone);
        setContentState({
            items: clone,
            edited: true,
            loaded: true,
            viewMode: ViewMode.Folders,
            viewingItem: {
                item: mkUnderedit,
            },
        });
    };

    const folders = contentState.viewingItem?.item.folders;

    return (
        <div>
            {foldersList(folders)}
            <AddNewItemForm handler={addNewFolder} />
        </div>
    );
};
