import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { I18n as amplifyI18n } from '@aws-amplify/core';

import { localizationOptions } from './localizationOptions';

i18n.use(initReactI18next).init(localizationOptions);
amplifyI18n.putVocabularies({
    ru: localizationOptions.resources!.ru.translation,
});
amplifyI18n.setLanguage('ru');

export default i18n;
