import { API, Auth } from 'aws-amplify';

import { SignedUrl, SignedUrlResponse } from './getObject';

export async function putObject(objectKey: string): Promise<SignedUrl> {
    console.log('Putting', objectKey);

    const apiName = 'object';
    const path = '/object';
    const myInit = {
        headers: {
            Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
        },
        queryStringParameters: {
            objectKey: objectKey,
        },
    };

    const response = (await API.post(
        apiName,
        path,
        myInit
    )) as SignedUrlResponse;
    if (!response) {
        throw new Error('Failed to get signed url to upload resource');
    }
    const url = response.result?.signedUrl;
    if (!url || url.length === 0) {
        throw new Error('Failed to get the url to upload resource' + objectKey);
    }
    console.log('Got url: ', url);
    return url;
}
