import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { SwApi } from '../../../api-client/SwApi';
import { contentEditState } from '../../../store/ContentEditStore';

export function SubmitItemsButton(props: { onClick?: Function }) {
    const [contentState, setContentState] = useRecoilState(contentEditState);
    const { t } = useTranslation();

    const save = async () => {
        console.debug('Saving new state');
        setContentState({
            items: contentState.items,
            loaded: contentState.loaded,
            edited: contentState.edited,
            saving: true,
            viewMode: contentState.viewMode,
            viewingItem: contentState.viewingItem,
        });

        const savedState = [...contentState.items];

        if (typeof props?.onClick === 'function') {
            props?.onClick();
        }

        await SwApi.updateItems(savedState);

        console.debug('Saving done');
        setContentState({
            items: savedState,
            loaded: true,
            edited: false,
            saving: false,
            viewMode: contentState.viewMode,
            viewingItem: contentState.viewingItem,
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.debug('Saving', contentState.items);
        save();
    };

    const submitValueText = t('Submit changes');

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input
                    type="submit"
                    value={submitValueText}
                    disabled={contentState.saving}
                />
            </form>
        </div>
    );
}
