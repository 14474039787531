import { API, Auth } from 'aws-amplify';
import { SwMasterClass } from 'sw-commons/lib/models/SwMasterClass';

export async function getMasterClass(
    id: number
): Promise<GetMasterClassResponse> {
    console.log('Getting single MK');
    const apiName = 'getMasterclass';
    const path = '/masterclass/' + id;
    const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
        },
    };

    const response = await API.get(apiName, path, myInit);
    if (!response) {
        throw new Error('Failed to get master class, no response');
    }
    const result = response.result as SwMasterClass;
    console.log('Result', result);

    if (!result) {
        throw new Error(
            'Failed to parse master class: ' + JSON.stringify(response)
        );
    }

    if (!result.name) {
        throw new Error('No access');
    }
    return result;
}

export type GetMasterClassResponse = SwMasterClass;
