import '../../UI/styles/components/MasterClassFolderView.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { SwMasterClass, SwResource } from 'sw-commons/lib/models/SwMasterClass';

import { MasterClassFoldersList } from './MasterClassFoldersList';

export function MasterClassFolderView({
    mk,
    setShowingResource,
}: {
    mk: SwMasterClass;
    setShowingResource: React.Dispatch<
        React.SetStateAction<false | SwResource>
    >;
}) {
    const { t } = useTranslation();

    return (
        <div className="masterclass-page__content masterclass">
            <div className="masterclass__thumbnail">
                <div className="masterclass__pic">
                    <img
                        src={mk.thumbnail}
                        alt="thumbnail"
                        // style={{ width: '150px', height: '150px' }}
                    />
                </div>
            </div>
            <div className="masterclass__description">
                <p className="masterclass__text">{mk.description}</p>
            </div>
            <div className="masterclass__head">
                <h2 className="masterclass__title title">{t('Materials')}</h2>
            </div>
            <MasterClassFoldersList {...{ mk, setShowingResource }} />
        </div>
    );
}
