import '../UI/styles/components/Main.scss';

import React from 'react';
import { StaticContext } from 'react-router';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { Homepage } from './Homepage';
import { MasterClassList } from './MasterClassListPage/MasterClassList';
import { MasterClass } from './MasterClassPage/MasterClass';

const ClientApp = () => {
    const basePage = () => {
        return (
            <div className="main">
                <Switch>
                    <Route exact path="/" children={<Homepage />} />
                    <Route exact path="/classes" component={MasterClassList} />
                    <Route
                        exact
                        path="/classes/:id"
                        children={(
                            props: JSX.IntrinsicAttributes &
                                RouteComponentProps<
                                    { id: string },
                                    StaticContext,
                                    unknown
                                >
                        ) => <MasterClass id={props.match.params.id} />}
                    />
                </Switch>
            </div>
        );
    };

    return <RecoilRoot>{basePage()}</RecoilRoot>;
};

export default ClientApp;
