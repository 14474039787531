import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useRecoilState } from 'recoil';
import {
    SwResource,
    SwResourceTypes,
} from 'sw-commons/lib/models/SwMasterClass';

// import { contentEditState } from '../../../store/ContentEditStore';
import { AdminTextContentEditor } from '../Editor/AdminContentEditor';

export const AdminResourceView = (props: { resource: SwResource }) => {
    const { t } = useTranslation();

    const resourceView = (resource: SwResource) => {
        if (resource.type === SwResourceTypes.TEXT) {
            return (
                <div>
                    <p>
                        {t('Editing text')} {resource.name} : {resource.type}
                    </p>
                    <AdminTextContentEditor />
                </div>
            );
        } else {
            return (
                <div>
                    <p>
                        {t('Editing video')} {resource.name} : {resource.type}
                    </p>
                </div>
            );
        }
    };

    return resourceView(props.resource);
};
