import { SwResourceTypes } from 'sw-commons/lib/models/SwMasterClass';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

export const listMasterClassMock: SwMasterClassList = [
    {
        id: 1,
        name: 'Павлова',
        thumbnail:
            'https://sweet-weekend.ru/wp-content/uploads/2020/04/IMG_2664-160x160.jpg',
        description:
            'An amazing description that just came from the mocked API, because failing to translate an existing description from Russian.',
        folders: [
            {
                folderName: 'Section 1',
                resources: [
                    {
                        type: SwResourceTypes.TEXT,
                        name: 'Text recepie',
                        objectKey: 'https://example.com',
                    },
                    {
                        type: SwResourceTypes.VIDEO,
                        name: 'Main video recepie',
                        duration: 3600000,
                        objectKey:
                            'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    },
                ],
            },
            {
                folderName: 'Section 2',
                resources: [
                    {
                        type: SwResourceTypes.VIDEO,
                        name: 'Extra video material recepie',
                        duration: 3600000,
                        objectKey:
                            'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        name: 'Десерты в стаканчиках',
        thumbnail:
            'https://sweet-weekend.ru/wp-content/uploads/2020/04/десерты-в-коробке-scaled-160x160.jpg',
        folders: [
            {
                folderName: 'Section 1',
                resources: [
                    {
                        type: SwResourceTypes.TEXT,
                        name: 'Text recepie',
                        objectKey: 'https://example.com',
                    },
                    {
                        type: SwResourceTypes.VIDEO,
                        name: 'Main video recepie',
                        duration: 3600000,
                        objectKey:
                            'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    },
                ],
            },
            {
                folderName: 'Section 2',
                resources: [
                    {
                        type: SwResourceTypes.VIDEO,
                        name: 'Extra video material recepie',
                        duration: 3600000,
                        objectKey:
                            'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        name: 'Медовик',
        thumbnail:
            'https://sweet-weekend.ru/wp-content/uploads/2020/04/IMG_7299-1-scaled-160x160.jpeg',
        folders: [
            {
                folderName: 'Section 1',
                resources: [
                    {
                        type: SwResourceTypes.TEXT,
                        name: 'Text recepie',
                        objectKey: 'https://example.com',
                    },
                    {
                        type: SwResourceTypes.VIDEO,
                        name: 'Main video recepie',
                        duration: 3600000,
                        objectKey:
                            'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    },
                ],
            },
            {
                folderName: 'Section 2',
                resources: [
                    {
                        type: SwResourceTypes.VIDEO,
                        name: 'Extra video material recepie',
                        duration: 3600000,
                        objectKey:
                            'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    },
                ],
            },
        ],
    },
    {
        id: 4,
        name: 'Чизкейк',
        thumbnail:
            'https://sweet-weekend.ru/wp-content/uploads/2020/04/IMG_7299-1-scaled-160x160.jpeg',
        folders: [
            {
                folderName: 'Main class',
                resources: [
                    {
                        name: 'Some Video',
                        type: SwResourceTypes.VIDEO,
                        objectKey:
                            'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    },
                ],
            },
        ],
    },
];
