import { API, Auth } from 'aws-amplify';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

export async function adminListMasterClass(): Promise<ListMasterClassResponse> {
    console.log('Getting available master classes');
    const apiName = 'masterclass';
    const path = '/admin/masterclass';
    const myInit = {
        headers: {
            Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
        },
    };

    const response = await API.get(apiName, path, myInit);
    if (!response) {
        throw new Error('Failed to get master classes, no response');
    }
    const result = response.result as SwMasterClassList;
    if (!result) {
        throw new Error(
            'Failed to parse master class: ' + JSON.stringify(response)
        );
    }
    console.log('Result', result);
    return result;
}

export type ListMasterClassResponse = SwMasterClassList;
