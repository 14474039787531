import React from 'react';
import { Link } from 'react-router-dom';

import logoImage from '../images/sw-cake-logo-crop-crop.png';

export const Logo = () => {
    return (
        <div className="logo">
            <Link to="/" className="logo__link">
                <img alt="logo" src={logoImage} />
            </Link>
        </div>
    );
};
