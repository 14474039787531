import '../UI/styles/components/CloseButton.scss';

import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';

export const CloseButton = ({ closeCallback }: { closeCallback: Function }) => {
    const { t } = useTranslation();

    return (
        <IoMdClose
            size="2em"
            onClick={() => closeCallback()}
            className="closeButton"
        >
            {t('Close')}
        </IoMdClose>
    );
};
