import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';
import { SwPermissions } from 'sw-commons/lib/models/SwPermissions';
import * as uuid from 'uuid';

import { adminListMasterClass } from './adminListMasterClass';
import { getMasterClass, GetMasterClassResponse } from './getMasterClass';
import { getObject, SignedUrl } from './getObject';
import { getPermissions } from './getPermissions';
import { listMasterClass, ListMasterClassResponse } from './listMasterClass';
import {
    getMasterClass as getMasterClassMock,
    getObject as getObjectMock,
    getPermissions as getPermissionsMock,
    listMasterClass as listMasterClassMock,
    putObject as putObjectMock,
    setPermissions as setPermissionsMock,
    updateItems as updateItemsMock,
} from './mocks';
import { putObject } from './putObject';
import { setPermissions } from './setPermissions';
import { updateItems } from './updateItems';

class SwApi {
    static usingMocks = (): boolean => {
        return SwApi.useMocks;
    };

    static listMasterClass(): Promise<ListMasterClassResponse> {
        return SwApi.usingMocks() ? listMasterClassMock() : listMasterClass();
    }

    static adminListMasterClass(): Promise<ListMasterClassResponse> {
        return SwApi.usingMocks()
            ? listMasterClassMock()
            : adminListMasterClass();
    }

    static getMasterClass(id: number): Promise<GetMasterClassResponse> {
        return SwApi.usingMocks() ? getMasterClassMock(id) : getMasterClass(id);
    }

    static updateItems(items: SwMasterClassList): Promise<void> {
        return SwApi.usingMocks() ? updateItemsMock(items) : updateItems(items);
    }

    static getPermissions(email: string): Promise<SwPermissions> {
        return SwApi.usingMocks()
            ? getPermissionsMock(email)
            : getPermissions(email);
    }

    static setPermissions(
        email: string,
        permissions: SwPermissions
    ): Promise<SwPermissions> {
        return SwApi.usingMocks()
            ? setPermissionsMock(email, permissions)
            : setPermissions(email, permissions);
    }

    static getObject(objectKey: string): Promise<SignedUrl> {
        return SwApi.usingMocks()
            ? getObjectMock(objectKey)
            : getObject(objectKey);
    }

    static putObject(objectKey: string): Promise<SignedUrl> {
        return SwApi.usingMocks()
            ? putObjectMock(objectKey)
            : putObject(objectKey);
    }

    static async fetchSigned(
        objectKey: string
    ): Promise<Record<string, unknown>> {
        const url = await SwApi.getObject(objectKey);
        console.log('Got signed url');
        if (url) {
            console.log('Fetching signed url');
            const result = await fetch(url);
            const body = await result.json();
            if (typeof body === 'object' && body !== null) {
                return body;
            } else {
                throw Error('No result json:' + JSON.stringify(result));
            }
        } else {
            throw Error('No url to fetch the object from');
        }
    }

    static async fetchVideo(objectKey: string): Promise<string> {
        const url = await SwApi.getObject(objectKey);
        console.log('Got signed url');
        if (url) {
            return url;
        } else {
            throw Error('No url to fetch the object from');
        }
    }

    static async uploadSigned({
        objectKey,
        data,
        path,
    }: {
        objectKey: string;
        data: string | File | Blob;
        path?: string;
    }): Promise<{
        newObjectKey: string;
    }> {
        let desiredObjectKey = objectKey.length === 0 ? uuid.v4() : objectKey;

        if (path) {
            desiredObjectKey = path + '/' + desiredObjectKey;
        }

        const url = await SwApi.putObject(desiredObjectKey);
        console.log('Got signed url for uploading');
        if (url) {
            console.log('Fetching signed url');
            const init: RequestInit = {
                method: 'put',
                body: data,
            };
            const result = await fetch(url, init);
            if (result.ok) {
                return {
                    newObjectKey: desiredObjectKey,
                };
            } else {
                throw Error('Not ok upload result:' + JSON.stringify(result));
            }
        } else {
            throw Error('No url to upload to');
        }
    }

    static toggleUseMocks(): boolean {
        if (this.useMocks) {
            this.setUseMocks(false);
        } else {
            this.setUseMocks(true);
        }
        console.log('API mocked:', this.useMocks);
        return this.useMocks;
    }

    static setUseMocks(val: boolean): void {
        this.useMocks = val;
    }
    static useMocks = false;
}

export { SwApi };
