import '../../UI/styles/components/MasterClassListItem.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { SwMasterClass } from 'sw-commons/lib/models/SwMasterClass';

import thumbnail from '../../../src/images/IMG_0847-460x700.jpeg';

export const MasterClassListItem = ({
    data,
}: {
    data: Partial<SwMasterClass>;
}) => {
    const thumbnailImage = data.thumbnail || thumbnail;
    return (
        <div
            className="strip__wrap"
            style={{
                background: `url(${thumbnailImage}) center center / cover no-repeat`,
            }}
        >
            <Link className="strip__link" to={'/classes/' + data.id}>
                <div className="strip__title">
                    <div className="strip__text">
                        <p>{data.name}</p>
                    </div>
                </div>
            </Link>
        </div>
    );
};
