import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
    SwFolder,
    SwMasterClass,
    SwResource,
    SwResourceTypes,
} from 'sw-commons/lib/models/SwMasterClass';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

import {
    contentEditState,
    ViewingItem,
    ViewMode,
} from '../../../store/ContentEditStore';
import { AddNewResourceForm } from './AddNewResourceForm';

export const AdminFolderView = () => {
    const [contentState, setContentState] = useRecoilState(contentEditState);
    const { t } = useTranslation();

    const folderView = (folder: SwFolder) => {
        if (!folder.resources || folder.resources.length === 0) {
            return (
                <div>
                    <p>{t('No resources')}</p>
                </div>
            );
        }
        return (
            <div>
                {folder.resources?.map((resource) => {
                    return (
                        <div className="centered" key={resource.name}>
                            <p
                                onClick={() => {
                                    setContentState({
                                        items: contentState.items,
                                        viewMode: ViewMode.Resource,
                                        viewingItem: {
                                            item: contentState.viewingItem!
                                                .item,
                                            itemFolder: folder,
                                            resource: resource,
                                        },
                                        edited: contentState.edited,
                                        loaded: contentState.loaded,
                                    });
                                }}
                            >
                                {resource.name} : {resource.type}
                            </p>
                            <div
                                onClick={() => {
                                    const {
                                        items,
                                        viewingItem,
                                    } = deleteResource(
                                        resource,
                                        contentState.items,
                                        contentState.viewingItem!
                                    );

                                    console.log('Resource deleted, redrawing');

                                    setContentState({
                                        items: items,
                                        viewMode: ViewMode.Folder,
                                        viewingItem: viewingItem,
                                        edited: true,
                                        loaded: contentState.loaded,
                                    });
                                }}
                            >
                                <div className="deleteButton">❌</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    function addResource(name: string, type: SwResourceTypes): void {
        if (name.length === 0) {
            console.warn('No resource name provided, not adding');
            return;
        }
        const clone: SwMasterClass[] = [];
        contentState.items.forEach((e) =>
            clone.push(JSON.parse(JSON.stringify(e)))
        );

        const mkUnderedit = clone.find(
            (mk) => mk.id === contentState.viewingItem?.item.id
        );
        if (!mkUnderedit) {
            throw new Error('Not found MK to edit');
        }

        console.debug('Editing resources for', mkUnderedit);

        const folderUnderEdit = mkUnderedit.folders?.find(
            (f) =>
                f.folderName ===
                contentState.viewingItem?.itemFolder?.folderName
        );
        if (!folderUnderEdit) {
            throw new Error('Not found MK to edit');
        }
        folderUnderEdit.resources = folderUnderEdit.resources ?? [];

        if (type === SwResourceTypes.TEXT) {
            folderUnderEdit.resources?.push({
                type: type,
                name: name,
                objectKey: '',
            });
        } else {
            folderUnderEdit.resources?.push({
                type: type,
                name: name,
                objectKey: '',
                duration: 0,
            });
        }

        setContentState({
            items: clone,
            edited: true,
            loaded: true,
            viewMode: ViewMode.Folder,
            viewingItem: {
                item: mkUnderedit,
                itemFolder: folderUnderEdit,
            },
        });
        console.log('final', contentState);
    }

    const folder = contentState.viewingItem?.itemFolder;
    if (!folder) {
        throw Error('Trying to render a folder view without a folder to view');
    }

    return (
        <div>
            {folderView(folder)}
            <AddNewResourceForm handler={addResource} />
        </div>
    );
};

function deleteResource(
    resource: SwResource,
    items: SwMasterClassList,
    viewingItem: ViewingItem
): { items: SwMasterClassList; viewingItem: ViewingItem } {
    console.log('Deleting resource');

    const clone: SwMasterClass[] = [];
    items.forEach((e) => clone.push(JSON.parse(JSON.stringify(e))));

    const itemToEdit = clone.find((i) => i.id === viewingItem.item.id);
    if (!itemToEdit) {
        throw Error('Cant find item to edit');
    }

    console.debug('item to edit:', itemToEdit);

    const folderInClone = itemToEdit?.folders?.find((f) => {
        return f.folderName === viewingItem.itemFolder?.folderName;
    });

    if (!folderInClone) {
        throw Error('Cant find folder to edit');
    }

    const indexToDelete = folderInClone.resources?.findIndex((r) => {
        return r.name === resource.name && r.type === resource.type;
    });

    if (indexToDelete === undefined || indexToDelete === -1) {
        throw Error('Cant find resource to delete');
    }

    const deleted = folderInClone.resources?.splice(indexToDelete, 1);
    if (!deleted) {
        throw Error('Failed to delete anything');
    }

    return {
        items: clone,
        viewingItem: {
            item: itemToEdit,
            itemFolder: folderInClone,
        },
    };
}
