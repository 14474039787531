import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

console.log('sentry go');

Sentry.init({
    dsn:
        'https://8e021f3a80ca45288264bdc753155ae0@o732359.ingest.sentry.io/5784179',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 1.0,
});
