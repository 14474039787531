import '../../UI/styles/components/VideoResource.scss';
import 'shaka-player/dist/controls.css';

import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { SwVideoResource } from 'sw-commons/lib/models/SwMasterClass';

import { SwApi } from '../../api-client/SwApi';
import { LoadingSpinerComponent } from '../LoadingSpinerComponent';

export const VideoResource = ({
    data,
}: {
    data: {
        resource: SwVideoResource;
        closeCallback: Function;
    };
}) => {
    const [error, setError] = useState<undefined | Error>();
    const [resource, setResource] = useState<undefined | string>();

    useEffect(() => {
        const apiCall = async () => {
            try {
                if (data.resource.objectKey.startsWith('http')) {
                    console.log('using web');
                    setResource(data.resource.objectKey);
                } else {
                    console.log('using s3');
                    const response = await trackPromise(
                        SwApi.fetchVideo(data.resource.objectKey)
                    );
                    console.debug('Fetch returned', response);
                    setResource(response);
                }
            } catch (e) {
                console.error(e);
                setError(e);
            }
        };
        apiCall();
    }, [data.resource.objectKey]);

    return (
        <div className="video-resource">
            {error ? <p>{error.message}</p> : null}

            {resource ? VideoPlayer(resource) : <LoadingSpinerComponent />}
        </div>
    );
};

const VideoPlayer = (resource: string) => {
    const simple = (
        <video
            className="video-resource__player"
            controls
            controlsList="nodownload"
        >
            <source src={resource} type="video/mp4" />
        </video>
    );

    const vimeo = (url: string) => {
        console.log(url);
        const id = url.split('/')[3];
        if (!id) {
            console.error('No vimeo id');
            return <p>Bad video id</p>;
        }
        return (
            <div>
                <iframe
                    src={`https://player.vimeo.com/video/${id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    title="Player"
                    width="100%"
                    height="100%"
                ></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        );
    };

    if (resource.indexOf('vimeo.com') > -1) {
        return vimeo(resource);
    }

    return simple;
};
