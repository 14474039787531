import { API, Auth } from 'aws-amplify';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

export async function updateItems(items: SwMasterClassList): Promise<void> {
    console.log('Updating remote items');
    const apiName = 'masterclass';
    const path = '/masterclass';
    const myInit = {
        headers: {
            Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
        },
        body: items,
    };

    const response = await API.post(apiName, path, myInit);
    if (!response) {
        throw new Error('Failed to update items, no response');
    }
    console.log('Updated remote items sucessfully');
}
