import '../../UI/styles/components/MasterClass.scss';

import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { SwMasterClass, SwResource } from 'sw-commons/lib/models/SwMasterClass';

import { SwApi } from '../../api-client/SwApi';
import { BackButton } from '../BackButton';
import { CloseButton } from '../CloseButton';
import { LoadingSpinerComponent } from '../LoadingSpinerComponent';
import { MasterClassFolderView } from './MasterClassFolderView';
import { ResourceView } from './MasterClassResourceView';
import { useHistory } from 'react-router-dom';

export function MasterClass({ id }: { id: number | string }) {
    let [isLoading, setIsLoading] = useState(true);
    let [error, setError] = useState<Error | undefined>(undefined);
    let [result, setResult] = useState<undefined | SwMasterClass>();
    let [isShowingResource, setShowingResource] = useState<false | SwResource>(
        false
    );
    let history = useHistory();

    useEffect(() => {
        const apiCall = async (): Promise<void> => {
            try {
                if (!result) {
                    setIsLoading(true);
                    const data = await trackPromise(
                        SwApi.getMasterClass(Number(id))
                    );
                    setResult(data);
                }
                setIsLoading(false);
            } catch (e) {
                setError(e);
            }
        };

        if (!isShowingResource) {
            history.replace({ hash: '' });
        }
        if (!result) {
            console.log('calling remote');
            apiCall();
        } else {
            setIsLoading(false);
        }
    }, [isLoading, id, result, isShowingResource, history]);

    if (error) {
        return <p>{JSON.stringify(error.message)}</p>;
    }
    if (isLoading) {
        return <LoadingSpinerComponent />;
    }

    if (!(result as SwMasterClass).id) {
        console.error('Bad API response', result);
        return null;
    }

    const mk = result as SwMasterClass;

    const closeCallback = () => {
        console.log('close callback');
        setShowingResource(false);
    };

    return (
        <div className="masterclass-page">
            <MasterClassHeader
                mk={mk}
                isShowingResource={isShowingResource}
                closeCallback={closeCallback}
            />
            {isShowingResource ? (
                <ResourceView {...{ isShowingResource, closeCallback }} />
            ) : (
                <MasterClassFolderView {...{ mk, setShowingResource }} />
            )}
        </div>
    );
}

function MasterClassHeader({
    mk,
    isShowingResource,
    closeCallback,
}: {
    mk: SwMasterClass;
    isShowingResource?: false | SwResource;
    closeCallback: Function;
}) {
    return (
        <div className="masterclass-page__header">
            {isShowingResource ? (
                CloseButton({ closeCallback: closeCallback })
            ) : (
                <BackButton hide={isShowingResource ? true : false} />
            )}
            <p className="masterclass-page__header-title">{mk.name}</p>
        </div>
    );
}
