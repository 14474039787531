/* eslint-disable quotes */
import { InitOptions } from 'i18next';

export const localizationOptions: InitOptions = {
    resources: {
        en: {
            translation: {
                'Access Manager': 'Access Manager',
                'Add new': 'Add new',
                'Admin access': 'Admin access',
                'Admin mode': 'Admin mode',
                'Api': 'Api!',
                'Back to': 'Back to',
                'Back': 'Back!',
                'Content manager': 'Content manager',
                'defaultUsernameGreeting': 'darling!',
                'Editing text': 'Editing text',
                'Editing video': 'Editing video',
                'Find user': 'Find user',
                'folders': 'folders',
                'Folders': 'Folders',
                'Forgot your password?': 'HUI',
                'live': 'live',
                'Loading...': 'Loading...',
                'Manage access': 'Manage access',
                'Manage content': 'Manage content',
                'Master Classes': 'Master Classes',
                'Materials': 'Materials',
                'mock': 'mock',
                'name': 'name',
                'No folders': 'No folders',
                'No items': 'No items',
                'No resources': 'No resources',
                'No response from API': 'No response from API',
                'Raw mode': 'Raw mode',
                'Save': 'Save',
                'Saved': 'Saved',
                'Search results:': 'Search results:',
                'Submit changes': 'Submit changes',
                'Text': 'Text',
                'Video': 'Video',
                'Welcome': 'Welcome, ',
                'Write text here...': 'Write text here...',
                'Your library': 'Your library',
            },
        },
        ru: {
            translation: {
                'Access Manager': 'Управление доступом',
                'Account recovery requires verified contact information': 'Необходимо подтвердить следующие учетные данные',
                'Add new': 'Добавить новый',
                'Admin access': 'Админка',
                'Admin mode': 'Режим администратора',
                'Api': 'Api!',
                'Back to Sign In': 'Назад ко входу',
                'Back to': 'Назад к',
                'Back': 'Назад',
                'Close': 'Закрыть',
                'Confirm SMS Code': 'Введите код из СМС',
                'Confirm': 'Подтвердить',
                'Confirmation Code': 'Код подтверждения',
                'Content manager': 'Управление контентом',
                'Create a new account': 'Создать новый аккаунт',
                'Create account': 'Создайте аккаунт',
                'Create Account': 'Создать аккаунт',
                'Create password *': 'Придумайте пароль *',
                'defaultUsernameGreeting': 'дорогуша!',
                'Editing text': 'Редактируем текст',
                'Editing video': 'Редактируем видео',
                'Email *': 'Емейл *',
                'Email Address *': 'Емейл *',
                'Email Address': 'Емейл',
                'Email': 'Емейл',
                'Enter code': 'Введите код',
                'Enter your code': 'Введите код',
                'Enter your email address': 'Введите свой емейл',
                'Enter your new password': 'Введите новый пароль',
                'Enter your password': 'Введите пароль',
                'Enter your username': 'Введите ваше имя пользователя',
                'Find user': 'Найти пользователя',
                'folders': 'все папки',
                'Folders': 'Папки',
                'Forgot your password?': 'Забыли пароль?',
                'Have an account?': 'Уже есть аккаунт?',
                'Help': 'Помощь',
                'live': 'live',
                'Loading...': 'Загрузка...',
                'Login with Google': 'Войти через Google',
                'Lost your code?': 'Не пришел код?',
                'Manage access': 'Управление доступом',
                'Manage content': 'Управление контентом',
                'Master Classes': 'Мастер Классы',
                'Materials': 'Материалы',
                'mock': 'mock',
                'name': 'имя',
                'New password': 'Новый пароль',
                'No account? Create account': 'Нет аккаунта? Создайте аккаунт',
                'No account?': 'Нет аккаунта?',
                'No avaialable master classes': "Нет доступных уроков",
                'No folders': 'Нет папок',
                'No items': 'Нет уроков',
                'No resources': 'Нет материалов',
                'No response from API': 'Нет ответа от сервера',
                'or': 'или',
                'Password *': 'Пароль *',
                'Password': 'Пароль',
                'Phone Number *': 'Номер телефона *',
                'Privacy Policy': 'Политика конфиденциальности',
                'Profile': 'Профиль',
                'Raw mode': 'Raw mode',
                'Resend Code': 'Выслать еще раз',
                'Reset password': 'Восстановить пароль',
                'Reset your password': 'Восстановить пароль',
                'Save': 'Сохранить',
                'Saved': 'Сохранено',
                'Skip': 'Пропустить',
                'Search results:': 'Результаты поиска:',
                'Send Code': 'Получить код',
                'Sign in to your account': 'Заходите!',
                'Sign in': 'Войти',
                'Sign In': 'Войти',
                'Sign Out': 'Выйти',
                'Submit changes': 'Отправить изменения',
                'Submit': 'Отправить',
                'Terms and conditions': 'Условия пользования',
                'Text': 'Текст',
                'Username *': 'Имя пользователя *',
                'Username cannot be empty': 'Имя пользователя или email пустые',
                'Username': 'Имя пользователя',
                'Verify': 'Подтвердить',
                'Verification code': 'Код верификации',
                'Video': 'Видео',
                'Welcome': 'Добро пожаловать, ',
                'Write text here...': 'Начни писать тут...',
                'Your library': 'Ваша библиотека',
            },
        },
    },
    lng: 'ru',
    fallbackLng: 'en',

    interpolation: {
        escapeValue: false,
    },
};
