import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';
import { SwPermissions } from 'sw-commons/lib/models/SwPermissions';

import { GetMasterClassResponse } from './getMasterClass';
import { ListMasterClassResponse } from './listMasterClass';
import { listMasterClassMock } from './mocks/listMasterClassMock';

export async function getMasterClass(
    id: number
): Promise<GetMasterClassResponse> {
    const list = listMasterClassMock as SwMasterClassList;
    const search = list.find((mk) => Number(mk.id) === id);
    if (!search) {
        throw new Error('Missing mock for ' + id);
    }
    return Promise.resolve(search);
}

export async function listMasterClass(): Promise<ListMasterClassResponse> {
    return Promise.resolve(listMasterClassMock as SwMasterClassList);
}

export async function updateItems(items: SwMasterClassList): Promise<void> {
    console.debug('Fake storing items', items);
    return Promise.resolve();
}

export async function getPermissions(email: string): Promise<SwPermissions> {
    console.debug(`Fake permissions read for ${email}`);
    return [1, 4];
}

export async function setPermissions(
    email: string,
    permissions: SwPermissions
): Promise<SwPermissions> {
    console.debug(`Fake permissions set to ${email}`);
    return permissions;
}

export async function getObject(objectKey: string): Promise<string> {
    console.debug('Retreiving fake signed url for ', objectKey);
    return `${
        objectKey +
        '?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJQ6UAEQOACU54C3A%2F20180927%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20180927T100139Z&X-Amz-Expires=900&X-Amz-Signature=f6fa35129753e7626c850a531379436a555447bfbd597c19e3177ae3d2c48387&X-Amz-SignedHeaders=host'
    }`;
}

export async function putObject(objectKey: string): Promise<string> {
    console.debug('Retreiving fake signed url for uploading ', objectKey);
    return `${
        objectKey +
        '?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJQ6UAEQOACU54C3A%2F20180927%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20180927T100139Z&X-Amz-Expires=900&X-Amz-Signature=f6fa35129753e7626c850a531379436a555447bfbd597c19e3177ae3d2c48387&X-Amz-SignedHeaders=host'
    }`;
}
