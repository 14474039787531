import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
    convertFromRaw,
    convertToRaw,
    EditorState,
    RawDraftContentState,
} from 'draft-js';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
    SwFolder,
    SwMasterClass,
    SwResourceTypes,
    SwTextResource,
} from 'sw-commons/lib/models/SwMasterClass';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

import { SwApi } from '../../../api-client/SwApi';
import { contentEditState, ViewingItem } from '../../../store/ContentEditStore';

export const AdminTextContentEditor = () => {
    const [contentState] = useRecoilState(contentEditState);
    const [editorState, setEditorState] = useState<undefined | EditorState>();
    const [error, setError] = useState<undefined | Error>();
    const [rawData, setRawData] = useState<undefined | RawDraftContentState>(
        undefined
    );
    const { t } = useTranslation();

    useEffect(() => {
        const apiCall = async () => {
            if (!editorState) {
                setEditorState(EditorState.createEmpty());
                return;
            }
            const currentContent = editorState.getCurrentContent();
            const resource = contentState.viewingItem
                ?.resource as SwTextResource;
            if (!resource) {
                throw Error('No resource found to display');
            }
            if (!currentContent.hasText()) {
                console.log('restoring content', resource);

                if (rawData) {
                    console.log('Restoring from rawData');
                    const savedContent = convertFromRaw(
                        (rawData as unknown) as RawDraftContentState
                    );
                    setEditorState(EditorState.createWithContent(savedContent));
                    return;
                } else {
                    if (resource.objectKey.length === 0) {
                        console.debug('No object key, do nothing');
                    } else {
                        try {
                            const result = await SwApi.fetchSigned(
                                resource.objectKey
                            );
                            setRawData(
                                (result as unknown) as RawDraftContentState
                            );
                        } catch (e) {
                            setError(e);
                        }
                    }
                }
            }
        };
        apiCall();
        return () => {};
    }, [editorState, contentState.viewingItem?.resource, rawData]);

    const decidePlaceholder = () => {
        const objectKey = contentState.viewingItem?.resource?.objectKey;
        return objectKey && objectKey.length > 0
            ? t('Loading...')
            : t('Write text here...');
    };

    return (
        <div>
            {error ? <p>{error.message}</p> : null}

            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditorState}
                placeholder={decidePlaceholder()}
                spellCheck={true}
                toolbar={{
                    options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'list',
                        'textAlign',
                        'history',
                    ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                }}
            />

            <SaveResult editorState={editorState} />
        </div>
    );
};

const SaveResult = (props: { editorState?: EditorState }) => {
    const [result, setResult] = useState<string>();
    const [contentState, setContentState] = useRecoilState(contentEditState);
    const { t } = useTranslation();

    const resultBlock = (result?: string) => {
        if (!props.editorState) {
            console.debug('no editor state');
            return null;
        }
        if (result) {
            return <p>{result}</p>;
        }
    };

    return (
        <div>
            {resultBlock(result)}
            <button
                onClick={async () => {
                    const rawData = convertToRaw(
                        props.editorState!.getCurrentContent()
                    );
                    const rawDataString = JSON.stringify(rawData);
                    setResult(t('Saved'));
                    const {
                        newItems,
                        newViewingItem,
                    } = await overwriteResource({
                        folder: contentState.viewingItem?.itemFolder!,
                        mk: contentState.viewingItem?.item!,
                        resource: contentState.viewingItem
                            ?.resource! as SwTextResource,
                        resourceRawDataString: rawDataString,
                        stateItems: contentState.items,
                    });
                    console.debug('Changing state on save');
                    setContentState({
                        edited: true,
                        items: newItems,
                        loaded: contentState.loaded,
                        viewMode: contentState.viewMode,
                        viewingItem: newViewingItem,
                    });
                }}
            >
                {t('Save')}
            </button>
        </div>
    );
};

async function overwriteResource({
    resourceRawDataString,
    mk,
    folder,
    resource,
    stateItems,
}: {
    resourceRawDataString: string;
    mk: SwMasterClass;
    folder: SwFolder;
    resource: SwTextResource;
    stateItems: SwMasterClassList;
}): Promise<{ newItems: SwMasterClassList; newViewingItem: ViewingItem }> {
    const clonedItems = JSON.parse(
        JSON.stringify(stateItems)
    ) as SwMasterClassList;

    const mkToEdit = clonedItems.find((e) => e.id === mk.id);
    if (!mkToEdit) {
        throw Error('Not found MK to overwrite');
    }

    const folderToEdit = mkToEdit.folders?.find(
        (e) => e.folderName === folder.folderName
    );
    if (!folderToEdit) {
        throw Error('Not found folder to overwrite');
    }
    const resourceToEdit = folderToEdit.resources?.find(
        (e) => e.name === resource.name
    );
    if (!resourceToEdit) {
        throw Error('Not found resource to overwrite');
    }
    if (resourceToEdit.type === SwResourceTypes.TEXT) {
        // publish edited resource

        //TODO do not fetch resource if no object key. Means its new
        const uploadResult = await SwApi.uploadSigned({
            objectKey: resourceToEdit.objectKey,
            data: resourceRawDataString,
            path:
                ((mkToEdit as unknown) as any).resourceBasePath ||
                mkToEdit.id.toString(),
        });
        resourceToEdit.objectKey = uploadResult.newObjectKey;
    } else {
        throw Error('Cannot edit video resources yet');
    }
    console.log('returning:', clonedItems);
    return {
        newItems: clonedItems,
        newViewingItem: {
            item: mkToEdit,
            itemFolder: folderToEdit,
            resource: resourceToEdit,
        },
    };
}
