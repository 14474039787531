import React from 'react';
import Loader from 'react-loader-spinner';
import { usePromiseTracker } from 'react-promise-tracker';

export const LoadingSpinerComponent = () => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        <div>
            {promiseInProgress === true ? (
                <Loader
                    type="Oval"
                    color="#bfbfbf"
                    timeout={10000}
                    radius={50}
                />
            ) : null}
        </div>
    );
};
