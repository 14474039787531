import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function AddNewItemForm(props: { handler: (name: string) => void }) {
    const [value, setValue] = useState('');
    const { t } = useTranslation();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.debug('Submitting', value);
        props.handler(value);
        setValue('');
    };

    const submitValueText = t('Add new');

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input
                    onChange={(e) => setValue(e.target.value)}
                    type="text"
                    name={t('name')}
                    value={value}
                />
                <input
                    type="submit"
                    value={submitValueText}
                    disabled={!value}
                />
            </form>
        </div>
    );
}
