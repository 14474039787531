import '../UI/styles/components/Header.scss';

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line unused-imports/no-unused-imports
import { useTranslation } from 'react-i18next';
import { userState } from '../store/UserState';

import { getUserEmail, getUserState } from '../helpers/AuthHelpers';
import { Logo } from './Logo';
import { useRecoilState } from 'recoil';

export const Header = () => {
    const { t } = useTranslation();
    const [username, setUsername] = useState(() =>
        t('defaultUsernameGreeting')
    );
    const [state, setState] = useRecoilState(userState);

    useEffect(() => {
        async function queryUserData(): Promise<void> {
            if (!state) {
                setState(await getUserState());
                return;
            }
            setUsername(await getUserEmail(state));
        }
        queryUserData();
    }, [setState, state]);

    return (
        <div className="header">
            <div className="header__menu">
                <Logo />

                <h2 className="header__title">
                    {t('Welcome')} {username}
                </h2>
            </div>
        </div>
    );
};
