import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const AdminPanel = () => {
    const { t } = useTranslation();
    return (
        <div>
            <h1>{t('Admin mode')}</h1>
            <ul>
                <li>
                    <Link to="/admin/access">{t('Manage access')}</Link>
                </li>
                <li>
                    <Link to="/admin/content">{t('Manage content')}</Link>
                </li>
                <li>
                    <Link to="/admin/content/raw">{t('Raw mode')}</Link>
                </li>
            </ul>
        </div>
    );
};
