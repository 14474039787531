import '../UI/styles/components/BackButton.scss';

import { History } from 'history';
import { useTranslation } from 'react-i18next';
import { IoMdArrowBack } from 'react-icons/io';
import { useHistory, useLocation } from 'react-router-dom';

export const Item = (history: History) => {
    const { t } = useTranslation();

    return (
        <>
            <IoMdArrowBack
                className="backButton"
                onClick={() => history.goBack()}
                size="2em"
            >
                {t('Back')}
            </IoMdArrowBack>
        </>
    );
};

export const BackButton = ({ hide }: { hide?: boolean }) => {
    let history = useHistory();
    const path = useLocation().pathname;
    if (hide || path.length === 1) {
        return null;
    }
    return Item(history);
};
