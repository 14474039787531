import { API, Auth } from 'aws-amplify';
import { SwPermissions } from 'sw-commons/lib/models/SwPermissions';

export async function getPermissions(
    userEmail: string
): Promise<SwPermissions> {
    console.log('Permissions of', userEmail);
    const apiName = 'permissions';
    const path = '/permissions/' + userEmail;
    const myInit = {
        headers: {
            // no bearer
            Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
        },
    };

    const response = await API.get(apiName, path, myInit);
    if (!response) {
        throw new Error('Failed to get permissions, no response');
    }
    const result = response.result as SwPermissions;
    if (!result) {
        throw new Error(
            'Failed to parse permissions: ' + JSON.stringify(response)
        );
    }
    console.log('Result', result);
    return result;
}
