import { Auth } from 'aws-amplify';

//@ts-ignore
window.LOG_LEVEL = 'INFO';

const apiEndpoint = () => {
    if (process.env.REACT_APP_API_URL) {
        console.info(`Using API URL: ${process.env.REACT_APP_API_URL}`);
        return process.env.REACT_APP_API_URL;
    }
    return 'https://fxrar746zj.execute-api.eu-central-1.amazonaws.com/prod';
};

const environmentRedirectUrl = (): string => {
    console.log('env', process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'production') {
        return 'https://school.sweet-weekend.ru';
    } else {
        return 'http://localhost:3000';
    }
};

const userPoolId = (): string => {
    console.log('userPool', process.env.REACT_APP_USER_POOL_ID);
    if (process.env.REACT_APP_USER_POOL_ID) {
        console.log(
            `Using USER_POOL_ID: ${process.env.REACT_APP_USER_POOL_ID}`
        );
        return process.env.REACT_APP_USER_POOL_ID;
    } else {
        return 'eu-central-1_3nKKNAE1u';
    }
};

const userPoolWebClientId = (): string => {
    if (process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID) {
        console.log(
            'userPoolWebClientId',
            process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
        );
        return process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
    } else {
        return '72211kartmc1tqep5hqh7261u3';
    }
};

const configClient = {
    Auth: {
        region: 'eu-central-1',
        userPoolId: userPoolId(),
        userPoolWebClientId: userPoolWebClientId(),
        federationTarget: 'COGNITO_USER_POOLS',
        oauth: {
            domain: 'auth.sweet-weekend.ru',
            scope: [
                'phone',
                'email',
                'openid',
                'profile',
                'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: environmentRedirectUrl(),
            redirectSignOut: environmentRedirectUrl(),
            responseType: 'code',
        },
        googleClientId:
            '721462002887-bvmpig09shegppdbknpj9k50i59u3uv8.apps.googleusercontent.com',
    },
    API: {
        endpoints: [
            {
                name: 'masterclass',
                endpoint: apiEndpoint(),
            },
            {
                name: 'getMasterclass',
                endpoint: apiEndpoint(),
            },
            {
                name: 'permissions',
                endpoint: apiEndpoint(),
            },
            {
                name: 'object',
                endpoint: apiEndpoint(),
            },
        ],
        custom_header: async () => {
            console.log('Custom header f call');
            return {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            };
        },
        // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
    },
};

export { configClient };
