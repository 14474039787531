import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { SwMasterClassList } from 'sw-commons/lib/models/SwMasterClassList';

import { SwApi } from '../../../api-client/SwApi';
import { sharedAdminMasterClassListStore } from '../../../store/SharedAdminMasterClassListStore';

export const RawEditView = () => {
    const [remoteJson, setRemoteJson] = useState<string>('');
    const [localJson, setLocalJson] = useState<string>('');
    const [items, setItems] = useRecoilState(sharedAdminMasterClassListStore);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<undefined | Error>();
    const [inputValue, setInputValue] = useState('');

    const handleSave = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        console.debug('saving local state to remote');
        setLoading(true);
        try {
            await SwApi.updateItems(JSON.parse(localJson));
            setRemoteJson(localJson);
            console.log('updated remote storage');
        } catch (e) {
            console.error(e);
            setError(e.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        // todo refactor to share the same state
        // with content manager to avoid extra requests
        const updateInputs = (data: SwMasterClassList) => {
            console.debug('Updating items', data);
            setItems(data);
            const dataString = JSON.stringify(data);
            setRemoteJson(dataString);
            setLocalJson(dataString);
            setInputValue(dataString);
            setLoading(false);
        };

        const apiCall = async () => {
            if (!items || items.length === 0) {
                setLoading(true);

                const response = await SwApi.adminListMasterClass();
                if (response) {
                    updateInputs(response);
                } else {
                    console.error('No response from API');
                    setError(Error('No response from API'));
                }
            }
            if (!localJson) {
                updateInputs(items);
            }
        };

        apiCall();
    }, [setRemoteJson, items, remoteJson, setItems, localJson, inputValue]);

    return (
        <div>
            {error ? <p>{error.message}</p> : null}
            <form>
                <textarea
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        setLocalJson(e.target.value);
                    }}
                    value={inputValue}
                />
                {localJson === remoteJson ? null : (
                    <button onClick={handleSave} disabled={loading}>
                        Save
                    </button>
                )}
            </form>
        </div>
    );
};
